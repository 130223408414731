import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MyGallery from '../components/Slideshow';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.light,
        flexGrow: 1,
      },
      container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        display: 'flex',
        position: 'relative',
        height: "80%",
        width: "80%"
      },
      title: {
        marginBottom: theme.spacing(5),
        textAlign: 'center',
        margin: 'auto',
      },
});

function Contact(props) {
    const { classes } = props;

  return (
    <section className={classes.root}>
        
      <Container className={classes.container}>
        <Grid container spacing={1} direction={"column"}>
          <Grid item container>
            <Typography variant="h4" className={classes.title}>
              Let us help you with your special day!
            </Typography>
          </Grid>
          <Grid item container>
            <MyGallery />
          </Grid>
        </Grid>            
      </Container>
    </section>
  );
}

export default withStyles(styles)(Contact);
