export const photos = [
    {
      src: "./static/gallery/IMG_6463.jpg",
      width: 4,
      height: 3
    },
    {
      src: "./static/gallery/IMG_1412.jpg",
      width: 4,
      height: 4
    },
    {
      src: "./static/gallery/IMG_1573.jpg",
      width: 3,
      height: 5
    },
    {
      src: "./static/gallery/IMG_3674.jpg",
      width: 3,
      height: 4
    },
    {
      src: "./static/gallery/IMG_1803.jpg",
      width: 4,
      height: 4
    },
    {
      src: "./static/gallery/IMG_3939.jpg",
      width: 4,
      height: 3
    },
    {
      src: "./static/gallery/IMG_1615.jpg",
      width: 5,
      height: 3
    }
  ];
  