import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '../components/Typography';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.light,
        // height: 1000,
        flexGrow: 1,
      },
      paper: {
        maxHeight: '100%',
        elevation: 0,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      item: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
      container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        display: 'flex',
        position: 'relative',
      },
      img: {
        margin: 'auto',
        position: 'center',
        display: 'block',
        width: 300,
        height: 300,
      },
      title: {
        textAlign: 'center',
        margin: 'auto',
      },
      body: {
        margin: 'auto',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '80%',
        textAlign: 'center',
      }
});

function Information(props) {
    const { classes } = props;

  return (
<section className={classes.root}>
    <Container>

      <Grid container spacing={2} className={classes.container} >
      
        <Paper className={classes.paper}>
            <Grid container spacing={2} className={classes.item}>
                <Grid item xs={12} md={6}>
                    <img
                        className={classes.img}
                        src="/static/undraw_super_thank_you_obwk.svg"
                        alt="thanks"
                    />
                </Grid>

                <Grid item xs={12} md={6} sm container className={classes.body}>
                    <Typography variant="h6" className={classes.title}>
                        wishing well doves release
                    </Typography>
                    <Typography variant="h5" className={classes.body}>
                    {"We can release our pure white doves at any outside event to meet your personal needs, the only thing we cannot do is release the doves under water."}                        
                    </Typography>            
                </Grid>
            </Grid>
        </Paper>


        <Paper className={classes.paper}>
            <Grid container spacing={2} className={classes.item}>
                <Grid item xs={12} md={6} sm container>
                    <Typography variant="h6" className={classes.title}>
                        CELEBRATING SPECIAL OCCASIONS
                    </Typography>
                    <Typography variant="h5" className={classes.body}>
                        {"We have had the honour of releasing our white doves at most celebrations; "}
                        {"Weddings, Birthdays, Valentines, Engagements, Rock Concerts Shop Openings."}
                        <Typography variant="h5" className={classes.body}>
                        {"In fact "}
                        {"the sky is the limit as we can for fill those wonderful moments in life with a cascade of "}
                        {"pure white doves taking to the sky. We really do have the ability to create that wow factor "}
                        {"and cherished memories that will last a lifetime."}
                        </Typography>
                        
                    </Typography>            
                </Grid>

                <Grid item xs={12} md={6}>
                    <img
                        className={classes.img}
                        src="/static/undraw_Birthday_cake_2wxy.svg"
                        alt="cake"
                    />
                </Grid>
            </Grid>
        </Paper>


        <Paper className={classes.paper}>
            <Grid container spacing={2} className={classes.item}>
                <Grid item xs={12} md={6}>
                    <img
                        className={classes.img}
                        src="/static/undraw_wedding_t1yl (1).svg"
                        alt="wedding"
                    />
                </Grid>

                <Grid item xs={12} md={6} sm container className={classes.body}>
                    <Typography variant="h6" className={classes.title}>
                    Weddings Celebrations
                    </Typography>
                    <Typography variant="h5" className={classes.body}>
                        {"Releasing pure white doves on your wedding day is becoming more and more popular and "}
                        {"we can arrange for the bride and groom to hold and release a dove each after they exit the church "}
                        {"and the photographer can capture the happy couple holding and releasing them."}
                        <Typography variant="h5" className={classes.body}>
                        {"We can also arrange to have 7 doves for luck, bride and groom hold 2 doves as above and we have "}
                        {" 5 more in a white basket that two of the bridesmaids release by pulling two ribbons, we match all the ribbons "}
                        {"to the colours of your choice."}
                        </Typography>
                        {"Doves pair for life so releasing them is a good omen as they take to the sky on their journey, just as the new "}
                        {"couple start their journey together as a partnership in life."}
                    </Typography>            
                </Grid>
            </Grid>
        </Paper>

        


        <Paper className={classes.paper}>
            <Grid container spacing={2} className={classes.item}>
                
                <Grid item xs={12} md={6} sm container>
                    <Typography variant="h6" className={classes.title}>
                        SAYING FAREWELL TO A LOVED ONE
                    </Typography>
                    <Typography variant="h5" className={classes.body}>
                        {"Releasing a white dove at the Graveside, Church or Crematorium is a special tribute to a loved one. "}
                    
                    <Typography variant="h5" className={classes.body}>
                        {"We can release a single dove if one or the other of the partners has departed, The remaining partner can hold and release "}
                        {"the dove, we can organise two doves if both partners have departed and the family can hold and release them. "}
                        {"The sight of the doves taking to the sky is a great farewell tribute for all present to experience."}
                    </Typography>
                        
                    </Typography>            
                    
                    <Typography variant="h5" className={classes.body} >
                        {"Oh that I had the wings of a dove! "}
                        {"I would fly away and be at rest."}
                    </Typography>
                    <Typography variant="h7" className={classes.body}> 
                        {"Psalm 55.6"}
                    </Typography>            
                </Grid>

                <Grid item xs={12} md={6}>
                    <img
                        className={classes.img}
                        src="/static/undraw_thoughts_e49y.svg"
                        alt="thinking"
                    />
                </Grid>

            </Grid>
        </Paper>
    </Grid>
      

 
  </Container>

     </section>
      
  );
}

export default withStyles(styles)(Information);
