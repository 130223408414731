import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '../components/Typography';

const styles = (theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.light,
        // height: 1000,
        flexGrow: 1,
      },
      paper: {
        maxHeight: '100%',
        elevation: 0,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      container: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(15),
        display: 'flex',
        position: 'relative',
      },
      img: {
        margin: 'auto',
        position: 'center',
        display: 'block',
        width: 300,
        height: 300,
      },
      title: {
        textAlign: 'center',
        margin: 'auto',
      },
      body: {
        margin: 'auto',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '80%',
        textAlign: 'center',
      },
      contact: {
        margin: 'auto',
        width: '80%',
        textAlign: 'center',
      }
});

function Contact(props) {
    const { classes } = props;

  return (
    <section className={classes.root}>
        <Container className={classes.container}>
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm container>
                    <Typography variant="h6" className={classes.title}>
                    Let us help you with your special day!
                    </Typography>
                    <Typography variant="h5" className={classes.body}>
                    {" We would love to assist you, drop us a message and we'll get back to you as soon as possible!"}
                    </Typography>
                    <Typography variant="h7" className={classes.contact}>
                    {"Mobile: 0771 3359 386"}
                    </Typography>   
                    <Typography variant="h7" className={classes.contact}>
                    {"Email: james@wishingwelldoves.co.uk"}
                    </Typography>         
                </Grid>

                <Grid item xs={12} md={6}>
                    <img
                        className={classes.img}
                        src="/static/undraw_Appreciation_sjc1.svg"
                        alt="wedding"
                    />
                </Grid>
            </Grid>
        </Paper>

            
        </Container>
    </section>
  );
}

export default withStyles(styles)(Contact);
