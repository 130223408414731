import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import ProductHero from './modules/views/ProductHero';
import AppAppBar from './modules/views/AppAppBar';
import Information from './modules/views/Information';
import Associated from './modules/views/Associated';
import Footer from './modules/components/Footer';

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <Information />
      <Associated />
      <Footer />
    </React.Fragment>
  );
}

export default withRoot(Index);
