import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '../components/Typography';

const styles = (theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.light,
        // height: 1000,
        flexGrow: 1,
      },
      container: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(15),
        display: 'flex',
        position: 'relative',
      },
      middle:{
        margin: 'auto',
        position: 'center',
      },
      img: {
        margin: 'auto',
        position: 'center',
        display: 'block',
        width: 150,
        height: 150,
      },
      title: {
        textAlign: 'center',
        margin: 'auto',
      },
      body: {
        margin: 'auto',
        // marginTop: theme.spacing(1),
        // marginBottom: theme.spacing(1),
        width: '80%',
        textAlign: 'center',
      }
});

function Information(props) {
    const { classes } = props;

  return (
<section className={classes.root}>
    <Container>

      <Grid container spacing={1} className={classes.container} >
      
            <Grid container spacing={5}>
                <Grid item xs={12} sm={3} className={classes.middle}>
                    <img
                        className={classes.img}
                        src="/static/13064718_1017246571658484_3897759163081329807_o.png"
                        alt="wedding"
                    />
                </Grid>

                <Grid item xs={12} sm={6} container className={classes.body}>

                    <Typography variant="h5" className={classes.body}>
                      {"We are please to say that we have a good professional relationship with all the local Funeral companies."}
                    </Typography> 
                    <Typography variant="h5" className={classes.body}>
                      {"In addition we are recognised by The National Association of Funeral Directors and "}
                      {"The National Society of Allied and Independent Funeral Directors."}
                    </Typography>
                                         
                               
                </Grid>
                <Grid item xs={12} sm={3} container className={classes.body}>
                <img
                        className={classes.img}
                        src="/static/SAIF-logo-MAIN-1-e1478277195626.png"
                        alt="wedding"
                    />           
                </Grid>
            </Grid>


    </Grid>
      

 
  </Container>

     </section>
      
  );
}

export default withStyles(styles)(Information);
