import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';


const styles = (theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: theme.palette.secondary.light,
        height: '100%',
        flexGrow: 1,
      },
      
      container: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(15),
        display: 'flex',
        position: 'relative',
      },
      img: {
        // marginTop: theme.spacing(15),
        marginBottom: theme.spacing(15),
        margin: 'auto',
        position: 'center',
        display: 'block',
        // height: '100%',
        // maxHeight: '80%',
        maxWidth: '80%',
        // width: '100%'
      },
      title: {
        textAlign: 'center',
        margin: 'auto',
      },
});

function Notfound(props) {
    const { classes } = props;

  return (
    <section className={classes.root}>
        <Container className={classes.container}>
            <Grid container spacing={2}>
                <Grid item>
                    <img
                    className={classes.img}
                    src="/static/undraw_page_not_found_su7k.svg"
                    alt="wedding" />
                </Grid>
            </Grid> 
        </Container>
    </section>
  );
}

export default withStyles(styles)(Notfound);
